import { useTranslation } from 'react-i18next';
import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';

const NotFoundPage = () => {
  const { t } = useTranslation();

  if (typeof window === 'undefined') {
    return null;
  }

  return (
    <Layout>
      <SEO title={t('nothing')} />
      <section className="container mx-auto pt-8 lg:pt-16">
        <div className="px-8">
          <div className="text-center py-16">
            <h1>
              {t('nothing')}
            </h1>
            <p className="subtitle mt-6">
              {t('pageNotExist')}
            </p>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default NotFoundPage;
